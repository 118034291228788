<template>
  <div class="homepage">
    <div class="content">
      <!-- 轮播用户学习信息 -->
      <div class="banner">
        <div class="banner-left">
          <template v-if="bannerList && bannerList.length > 1">
            <el-carousel trigger="click" :autoplay="true" height="328px">
              <el-carousel-item v-for="item in bannerList" :key="item.id + 'banner'">
                <img :src="item.picUrl" @click="handleBanner(item)" class="picUrl" alt="">
              </el-carousel-item>
            </el-carousel>
          </template>
          <template v-else>
            <img :src="bannerList[0]?.picUrl" @click="handleBanner(bannerList[0])" class="picUrl" alt="">
          </template>
        </div>

        <div class="bannerRight">
          <div class="user-info">
            <img :src="subUserInfo?.headImage ? subUserInfo.headImage : require('../../../assets/images/userL.png')"
              class="headImg" alt="" @click="goPersonal">
            <div class="right">
              <p class="label">{{ getTimeState() }}</p>
              <p class="name" @click="goPersonal">{{ subUserInfo?.name }}</p>
            </div>
          </div>
          <div class="learning-box">
            <div class="xueshi">
              <p class="num" v-if="totalLearnTime">{{ (totalLearnTime / 3600) >= 1 ? (totalLearnTime /
            3600).toFixed(2) : (totalLearnTime / 60).toFixed(2) }}</p>
              <p class="num" v-else>0</p>
              <p class="label">累计<br />完成学时({{ totalLearnTime / 3600 >= 1 ? 'h' : 'min' }})</p>
            </div>
            <div class="xuefen">
              <p class="num">{{ studyStatistics?.credit || 0 }}</p>
              <p class="label">累计<br />学分</p>
            </div>
            <div class="kecheng">
              <p class="num">{{ studyStatistics?.finishCourseCount || 0 }}</p>
              <p class="label">累计<br />完成课程</p>
            </div>
          </div>
        </div>

      </div>
      <div class="recentStudy">
        <div class="recentTitle">
          <div class="leftLine"></div>
          <div class="titleDes">
            最近学习
          </div>

        </div>
        <div class="recentStudyList" v-if="recentStudyList && recentStudyList.length > 0">
          <div class="recentStudyListMore" @click="learnPageClick">
            更多 >
          </div>

          <div class="courseList" v-if="recentStudyList.length">
            <div class="courseItem" v-for="(item) in recentStudyList" :key="item.id" @click="handlelearnDetail(item)">
              <div class="topImage">
                <img :src="item.coverImage" alt="">
              </div>
              <div class="courseName">
                {{ item.name }}
              </div>
              <div class="learnProgress" v-if="item.studyProcess > 0">
                <el-progress :percentage="item.studyProcess" :stroke-width="8" :color="customColors"
                  define-back-color="#dfdfdf" text-color="#666666"></el-progress>
              </div>
            </div>
          </div>
        </div>
        <empty-data v-if="recentStudyList && recentStudyList.length == 0"></empty-data>
        <div class="marginB">
        </div>

        <div class="recentTitle">
          <div class="leftLine"></div>
          <div class="titleDes">
            待学内容
          </div>
        </div>

        <div class="recentStudyList waitStudy" v-if="courseList && courseList.length > 0">
          <div class="recentStudyListMore" @click="learnPageClick">
            更多 >
          </div>
          <div class="courseList" v-if="courseList.length">
            <div class="courseItem" v-for="itemCourse in courseList" :key="itemCourse.id"
              @click="handlelearnDetail(itemCourse)">
              <div class="topImage">
                <img :src="itemCourse.coverImage" alt="">
              </div>
              <div class="courseName learnNameW">
                {{ itemCourse.name }}
              </div>
              <div class="learnProgress learnNameW" v-if="itemCourse.studyProcess > 0">
                <el-progress :percentage="itemCourse.studyProcess" :stroke-width="8" :color="customColors"
                  define-back-color="#dfdfdf" text-color="#666666"></el-progress>
              </div>
            </div>
          </div>
        </div>
        <empty-data v-if="courseList && courseList.length == 0"></empty-data>
      </div>

      <div class="goodsList" v-if="AllCourseList.length">
        <div class="goodsTitle">
          <!-- <span>{{className}}</span> -->
          <dd> 热门课程 </dd>
        </div>
        <div class="lineView">

        </div>
        <div class="moreBtn" @click="coursePageClick">
          更多 >
        </div>

        <div class="goodsListLine">
          <div class="goodsListLineItem" v-for="(itemC, indexcc) in AllCourseList" :key="indexcc"
            @click="handleDetail(itemC)">

            <div class="topImage">
              <img :src=itemC.coverImage alt="">
            </div>

            <div class="goodsName">
              {{ itemC.name }}
            </div>
            <div v-if="itemC.studyHour" class="studyHours">
              {{ setTimeForHoursNewNew(itemC.studyHour) }}课时
            </div>
            <!-- <div v-if="itemC.sell">
              <div class="priceV" v-if="itemC.goodsDto && !itemC.goodsDto.isFree">
                <span>
                  免费
                </span>
              </div>
              <div class="priceV" v-else>
                <span>
                  ¥{{ itemC.goodsDto?.salePrice || 0 }}
                </span>
                <dd>
                  ¥{{ itemC.goodsDto?.basePrice || 0 }}
                </dd>
              </div>
            </div> -->


          </div>



        </div>


      </div>
      <div class="classTop margin47 emerCase-main">
        <div class="emerCase" v-if="ArticlesList.length">
          <div class="classTopTitle">
            病症案例
          </div>
          <div class="lineView"></div>
          <div class="emerCase-content">
            <div class="emerCase-item" v-for="item in ArticlesList" :key="item.ucode" @click="toCase(item)">
              <div class="emerCase-box-top">
                <img :src="item.articleCover" alt="">
              </div>
              <div class="emerCase-box-bottom">
                <p class="name">{{ item.articleTitle }}</p>
                <p class="num">{{ item.articleAbstract }}</p>
              </div>
            </div>
          </div>
          <div class="moreBtn" @click="toJump({ path: '/emerCase' })">
            更多 >
          </div>
        </div>
      </div>
    </div>
    <div class="rank">
      <div class="rank-container">
        <div class="rank-item">
          <p class="header-rank">学习标兵排行榜</p>
          <div class="rank-item-content">
            <ul v-if="rankList && rankList.length">
              <li v-for="(item, index) in rankList" :key="index">
                <div class="rank-item-left">
                  <span class="rank-num">
                    <img v-if="index <= 2" :src="rankimg[index]" class="rank-sort" />
                    <span v-if="index > 2">{{ index + 1 }}</span>
                  </span>
                  <img :src="item.headImage ||
            'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-h5/default-avatar.png'
            " class="rank-avatar" />
                  <span class="username">{{ item.name }}</span>
                </div>
                <span class="rank-item-right">{{ item.value }}</span>
              </li>
            </ul>
            <empty-data v-if="rankList && rankList.length == 0"></empty-data>
          </div>
        </div>
        <div class="rank-item">
          <p class="header-rank">学习达人排行榜</p>
          <div class="rank-item-content">
            <ul v-if="rankList2 && rankList2.length">
              <li v-for="(item, index) in rankList2" :key="index">
                <div class="rank-item-left">
                  <span class="rank-num">
                    <img v-if="index <= 2" :src="rankimg[index]" class="rank-sort" />
                    <span v-if="index > 2">{{ index + 1 }}</span>
                  </span>
                  <img :src="item.headImage ||
            'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-h5/default-avatar.png'
            " class="rank-avatar" />
                  <span class="username">{{ item.name }}</span>
                </div>
                <span class="rank-item-right">学完{{ item.value }}门课</span>
              </li>
            </ul>
            <empty-data v-if="rankList2 && rankList2.length == 0"></empty-data>
          </div>
        </div>
        <div class="rank-item">
          <p class="header-rank">视频达人排行榜</p>
          <div class="rank-item-content">
            <ul v-if="rankList3 && rankList3.length">
              <li v-for="(item, index) in rankList3" :key="index">
                <div class="rank-item-left">
                  <span class="rank-num">
                    <img v-if="index <= 2" :src="rankimg[index]" class="rank-sort" />
                    <span v-if="index > 2">{{ index + 1 }}</span>
                  </span>
                  <img :src="item.headImage ||
            'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-h5/default-avatar.png'
            " class="rank-avatar" />
                  <span class="username">{{ item.name }}</span>
                </div>
                <span class="rank-item-right">发布视频{{ item.value }}个</span>
              </li>
            </ul>
            <empty-data v-if="rankList3 && rankList3.length == 0"></empty-data>
          </div>
        </div>
      </div>
    </div>
    <div class="ai-tools" v-if="toolVisible" v-wd-drag>
      <img @click="closeToolClick" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/gpt/close.png"
        alt="" class="close" />
      <div class="ai-tools-item">
        <div class=" redGpt" @click="changeVisibleOne">
          <img :src="$route?.meta?.gpt?.imgUrl" alt="" class="aiFloating" />
          <p>AI名师</p>
        </div>
      </div>
      <div class="ai-tools-item ai-tools-item-second">
        <div class="redGpt" @click="changeVisibleTwo">
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/question.png" alt=""
            class="aiFloating" />
          <p>风险识别</p>
        </div>

      </div>
    </div>
    <el-drawer :modal="false" :wrapperClosable="false" class="assistant-box" :size="560"
      :visible.sync="aiVisibleChatOne" :with-header="false">
      <ai-assistant @openAi="changeVisibleOne" :aiVisibleChat="aiVisibleChatOne" :gptType="$route.meta?.gpt?.gptType"
        :gptText="$route.meta?.gpt?.text" :contentId="$store.state.coures.currentlyPlay.contentId"
        v-if="aiVisibleChatOne"></ai-assistant>
    </el-drawer>
    <el-drawer :modal="false" :wrapperClosable="false" class="assistant-box" :size="560"
      :visible.sync="aiVisibleChatTwo" :with-header="false">
      <ai-assistant @openAi="changeVisibleTwo" :aiVisibleChat="aiVisibleChatTwo" :gptType="3" gptText="风险识别"
        v-if="aiVisibleChatTwo"></ai-assistant>
    </el-drawer>
  </div>
</template>

<script>
// import { getConfigureValue } from '@/utils/configure.js'
import AiAssistant from '@/modules/gpt/page/index.vue'
import wdDrag from '@/directive/drag' //拖拽

export default {
  computed: {
    subUserInfo() {
      return this.$store.state.subUserInfo
    }
  },
  components: {
    AiAssistant,
  },
  directives: {
    wdDrag
  },
  data() {
    return {
      toolVisible: true,
      rankimg: [
        "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/rank-first.png",
        "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/rank-second.png",
        "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/rank-third.png",
      ],
      ArticlesList: [],
      aiVisibleChatOne: false,
      aiVisibleChatTwo: false,
      AllCourseList: [],
      //courseClassIDList: [{ "classID": "22", "className": "三岗培训" }],
      customColors: '#D10000',
      isSettingPasswordHomeDialog: false,
      dialogVisible: {
        show: false,
      },
      token: '',
      rankingTabsIndex: 0,
      bannerList: [],
      rankingTabs: [
        {
          label: 2,
          value: '学分'
        },
        {
          label: 1,
          value: '学时'
        },
        {
          label: 3,
          value: '课程'
        }
      ],
      // 学分排行
      CreditRankingList: [],
      informationList: [], // 资讯列表
      newCourseTabs: [
        {
          label: 1,
          value: '最新',
        },
        {
          label: 2,
          value: '最热',
        },
        {
          label: 3,
          value: '精品',
        }
      ],
      newCourseList: [],
      recentStudyList: [],
      studyStatistics: {},
      totalLearnTime: 0,
      courseList: [],
      courseClassIDList: [],
      className: '',
      classID: '',
      classCourseListByIDData: [],
      pcDomain: '',
      rankList: [],
      rankList2: [],
      rankList3: [],
    }
  },
  async created() {
    this.token = localStorage.getItem('token');
    this.getArticlesList();
    this.getHomeData(1);
    await this.getConfiguration();
    if (this.token) {
      await this.getMyTaskList();
      await this.getCourseListNew();

      this.getAllLearnedData()
      // 增加热门课程
      this.getHotCourseList();
    }
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.myStudyStatistics();
      this.getData(1)
      this.getData(2)
      this.getData(3)
    }
  },
  methods: {
    setTimeForHoursNewNew(time) {
      if (time) {
        // 秒 转 分 然后45分是一课时 最后结果向上取整
        return Math.ceil(time / 60 / 45);
      }
      return 0;
    },
    closeToolClick() {
      console.log('closeToolClick')
      this.toolVisible = false;
    },
    getData(type) {
      // 数据类型 1:学习标兵 2:学习达人 3:视频达人
      const data = {
        dataType: type == 2 ? 3 : type,
      };
      let params = {
        pageNum: 1,
        pageSize: 10,
      };
      const that = this;
      this.$api.home.getUserTopCount({ params, data }).then((res) => {
        if (res.data) {
          if (type == 1) {
            const temp =
              res.data.dataList &&
              res.data.dataList.map((item) => {
                return {
                  ...item,
                  value: that.trnasTime(item.value),
                };
              });
            this.rankList = (temp || []).slice(0, 10);
          } else if (type == 2) {
            this.rankList2 = (res.data.dataList || []).slice(0, 10);
          } else if (type == 3) {
            this.rankList3 = (res.data.dataList || []).slice(0, 10);
          }
        }
      })
    },
    trnasTime(time) {
      let timeStr = 0;
      if (time / 60 < 60) {
        timeStr = (time / 60).toFixed(2) + "min";
      } else {
        timeStr = (time / 3600).toFixed(2) + "h";
      }
      return timeStr;
    },
    toJump(item) {
      this.$router.push({
        path: item.path
      })
    },
    toCase(item) {
      this.$router.push({
        path: "/cms/detail",
        query: {
          articleId: item.id,
        },
      });
    },
    getArticlesList() {
      const params = {
        pageNum: 1,
        pageSize: 8,
        sort: 1,
      };
      this.$api.ema.getArticlesByDomain(params).then((res) => {
        if (res.data) {
          if (res.data.list && res.data.list.length) {
            this.noData = false;
          } else {
            this.noData = true;
          }
          this.ArticlesList = res.data.list || [];
          this.total = res.data.total;
        }
      });
    },
    handlelearnDetail(item) {
      this.$router.push({
        path: '/learn/course/detail',
        query: {
          id: item.id,
        }
      })

    },

    handleDetail(item) {
      this.$router.push({
        path: "/course/detail",
        query: {
          ucode: item.ucode,
        },
      });
    },

    // 跳转学习中心
    learnPageClick() {
      this.$router.push({
        path: '/learn/list'
      })
    },

    coursePageClick() {
      this.$router.push({
        path: '/courseData'
      })
    },
    async getConfiguration() {
      await this.$api.configuration.getConfiguration().then(res => {
        if (res.code === 0 && res.data) {
          let frontendConfig = res.data.frontendConfig.filter(item => {
            if (item.key === 'courseClassIDList') {
              return true;
            }
          })[0];

          if (frontendConfig && frontendConfig.value) {
            this.courseClassIDList = JSON.parse(frontendConfig.value);
            console.log(this.courseClassIDList, 'this.courseClassIDList')

            // 根据课程分类获取课程列表
            // if (this.courseClassIDList.length) {
            // this.classID = this.courseClassIDList[0].classID;
            // this.className = this.courseClassIDList[0].className;

            // this.getCourseDataByClassID(this.classID);
            // }
          } else {
            this.courseClassIDList = [];
          }
        }
      });
    },

    getHotCourseList() {
      let params = {
        pageNum: 1,
        pageSize: 8,
      };
      let data = {
        categoryCode: '',
        orderBy: 0,
        name: ''

      }
      this.$api.courseCenter.getCourseList({ params, data }).then(res => {
        if (res.data && res.data.list && res.data.list.length) {
          this.AllCourseList = res.data.list || [];
        }
      })
    },

    getCourseDataByClassID(classID) {
      let params = {
        pageNum: 1,
        pageSize: 3,
      };
      let data = {
        categoryCode: classID,
        orderBy: 0,
        name: ''

      }
      this.$api.courseCenter.getCourseList({ params, data }).then(async (res) => {
        if (res.data && res.data.list && res.data.list.length) {
          // this.courseList = res.data.list || [];
          this.classCourseListByIDData = res.data.list || [];
        }
      });
    },

    // 获取学习时长
    getAllLearnedData() {
      this.$api.learn.getAllLearnedData().then((res) => {
        this.totalLearnTime = res.data.totalLearnTime || 0;
      });
    },
    changeVisibleOne(data) {
      if (!localStorage.getItem('token')) {
        this.$router.push({
          path: '/login'
        })
        return
      }
      if (document.querySelector('.gptFloating') && document.querySelector('.gptFloating').getAttribute('data-drag') === 'true') {
        return
      }
      if (data === false) {
        this.aiVisibleChatOne = false;
      } else {
        this.aiVisibleChatTwo = false;
        this.aiVisibleChatOne = !this.aiVisibleChatOne;
      }
    },
    changeVisibleTwo(data) {
      if (!localStorage.getItem('token')) {
        this.$router.push({
          path: '/login'
        })
        return
      }
      if (document.querySelector('.gptFloating') && document.querySelector('.gptFloating').getAttribute('data-drag') === 'true') {
        return
      }
      if (data === false) {
        this.aiVisibleChatTwo = false;
      } else {
        this.aiVisibleChatOne = false;
        this.aiVisibleChatTwo = !this.aiVisibleChatTwo;
      }
    },
    // 获取是否设置过密码，绑定弹窗
    async alreadySetPassword() {
      const res = await this.$api.usercenter.alreadySetPassword({});
      this.isSettingPasswordHomeDialog = res.data
      sessionStorage.setItem('isSettingPasswordHomeDialog', res.data)
      if (!this.isSettingPasswordHomeDialog) {
        this.dialogVisible.show = true;
      } else {
        this.dialogVisible.show = false;
      }

    },
    formatSeconds(value) {
      let result = parseInt(value);
      let h =
        Math.floor((result / 3600) % 24) < 10
          ? Math.floor((result / 3600) % 24)
          : Math.floor((result / 3600) % 24);

      let m =
        Math.floor((result / 60) % 60) < 10
          ? Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);

      let s =
        Math.floor(result % 60) < 10
          ? Math.floor(result % 60)
          : Math.floor(result % 60);

      let res = "";
      if (h !== 0) res += `${h}h`;

      if (m !== 0) res += `${m}min`;

      if (s !== 0) res += `${s}s`;

      return res;
    },
    goPersonal() {
      this.$router.push({
        path: '/personal'
      })
    },
    // 课程中心详情
    jumpCourseCenterDetail(item) {
      this.$router.push({
        path: '/course/detail',
        query: {
          ucode: item.objUcode,
        }
      })
    },
    // 学习中心详情
    jumpLearnDetail(item) {
      if (item.displayStyle === 1) {
        this.$router.push({
          path: '/learn/course/detail',
          query: {
            id: item.id,
          }
        })
      } else {
        this.$router.push({
          path: "/learn/StageMap",
          query: {
            id: item.id,
          },
        });
      }

    },
    // 资讯详情
    jumpInformationDetail(item) {
      this.$router.push({
        path: '/information/detail',
        query: {
          id: item.objId
        }
      })
    },
    // banner 跳转
    handleBanner(item) {
      console.log(item, 'reim=====')
      // 跳转对象类型 objType 1：文章 2：课程
      if (item.isJump) {
        if (item.objType === 1) {
          this.jumpInformationDetail(item)
        } else {
          this.jumpCourseCenterDetail(item)
        }
      }
    },
    myStudyStatistics() {
      const params = {
        hasCredit: true,//是否包括学分
        hasFinishCourseCount: true,//是否包含课程完成数量
      }
      this.$api.home.myStudyStatistics(params).then(res => {
        this.studyStatistics = res.data
      })
    },
    // 排行榜
    getUserTopCount(dataType) {
      const params = {
        pageNum: 1,
        pageSize: 10
      }
      const data = {
        dataType // 1:学时 2:学分 3:课程学习（学习完成数）
      }
      this.$api.home.getUserTopCount({ params, data }).then(res => {
        this.CreditRankingList = res.data?.dataList || []
      })
    },
    // 待学内容
    async getCourseListNew() {
      const params = {
        pageNum: 1,
        pageSize: 3,
        status: 3
      }
      let res = await this.$api.learn.getMyTaskList({ params })
      if (res.data && res.data.list && res.data.list.length) {
        this.courseList = res.data.list || []
      }

    },
    // 最近学习
    async getMyTaskList() {
      const params = {
        pageNum: 1,
        pageSize: 5,
        status: 1
      }
      let res = await this.$api.learn.getMyTaskList({ params })
      if (res.data && res.data.list && res.data.list.length) {
        this.recentStudyList = res.data?.list || []
        // this.recentStudyList = [];
        console.log(this.recentStudyList, 'this.recentStudyList')
      }

    },
    getHomeData(type, pageSize) {
      const params = {
        pageNum: 1,
        pageSize: pageSize || 10,
      }
      const data = {
        clientType: 1,
        dataType: type,//数据类型 1：轮番图 2：课程推荐 3：资讯
      }
      this.$api.home.getHomeData({ data, params }).then(res => {
        if (res.data) {
          if (type === 1) {
            this.bannerList = res.data.list || [];
          } else if (type === 2) {
            this.newCourseList = res.data.list || [];
          } else if (type === 3) {
            this.informationList = res.data.list || [];
          }
        }

        console.log(res, 'res===')
      })
    },
    getTimeState() {
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      let minute = timeNow.getMinutes();
      // 设置默认文字
      let state = ``;
      console.log(hours, minute, 'hours====')
      // 判断当前时间段
      if (hours >= 0 && hours < 8) {
        state = `早上好!`;
      } else if (hours >= 8 && hours < 11) {
        state = `上午好!`;
      } else if (hours >= 11 && hours < 13) {
        state = `中午好!`;
      } else if (hours >= 13 && hours < 17) {
        state = `下午好!`;
      } else if (hours >= 17 && hours < 24) {
        state = `晚上好!`;
      }
      return state;
    },
    // 排行榜 tab切换
    rankingTabsClick(index, item) {
      this.rankingTabsIndex = index;
      this.getUserTopCount(item.label)
      console.log(item, 'item')
    }
  }
}
</script>

<style lang="scss" scoped src="../asset/css/index.scss"></style>
<style lang="scss" scoped src="../../gpt/assets/css/index.scss"></style>

<style lang="scss" scoped>
.studyHours {
  min-width: 55px;
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #6E6E6E;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  margin-top: 10px;
}

.ai-tools {
  position: fixed;
  right: 80px;
  bottom: 200px;
  background: linear-gradient(180deg, #FFECEA 0%, #FFE2E2 100%);
  border-radius: 18px;
  z-index: 30;
  width: 120px;
  height: 318px;

  .ai-tools-item {
    padding: 25px 20px;
    position: relative;
    cursor: pointer;
  }

  .ai-tools-item-second {
    &::after {
      position: absolute;
      top: 0;
      height: 2px;
      background: #fff;
      content: '';
      width: 90px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .aiFloating {
    width: 75px;
    height: 75px;
  }

  .close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 14px;
    height: 14px;
    z-index: 66;
  }

  p {
    margin-top: 5px;
    background: #1A72FF;
    padding: 5px 12px;
    border-radius: 32px;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    background: linear-gradient(180deg, #FD8E90 0%, #FD4042 100%);
  }
}

.rank-item-left {
  display: flex;
  align-items: center;

  .username {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    color: #666666;
  }
}

.rank-item-right {
  width: 90px;
  text-align: left;
  display: inline-block;
}

img.rank-avatar {
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

img.rank-sort {
  width: 18px;
}

.rank-num {
  width: 20px;
  display: inline-block;
  height: 22.5px;
  text-align: center;
}

.asideFloat {
  position: absolute;
  right: -120px;
  top: 80px;

  .gptFloating {
    position: static;
  }
}

::v-deep .el-progress-bar__innerText {
  color: #fff !important;

}

.bannerRight {
  width: 317px;
  background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/home/ban-home.png') no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  border-left: none;
  margin-left: 18px;

  .user-info {
    margin-top: 50px;
    padding-left: 32px;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Regular, PingFang SC;

    .headImg {
      width: 72px;
      height: 72px;
      // border-radius: 50%;
      margin-right: 20px;
      cursor: pointer;
    }

    .right {
      .label {
        font-size: 24px;
        font-weight: 600;
        color: #141d4f;
        line-height: 33px;
        margin-bottom: 5px;
      }

      .name {
        width: 150px;
        font-size: 16px;
        font-weight: 400;
        color: #141d4f;
        line-height: 22px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
  }

  .learning-box {
    margin-top: 40px;
    display: flex;
    padding: 0 30px;
    align-items: center;
    justify-content: space-between;
    font-family: PingFangSC-Semibold, PingFang SC;
    text-align: center;

    .num {
      font-size: 22px;
      font-weight: 600;
      color: #353535;
      line-height: 40px;
    }

    .label {
      font-size: 16px;
      font-weight: 400;
      color: #353535;
      line-height: 18px;
      margin-top: 6px;
    }
  }
}

.marginB {
  width: 100%;
  height: 30px;
}

.recentStudy {
  width: 1200px;
  background-color: #FFFFFF !important;
  box-shadow: 0px 2px 10px 0px rgba(239, 215, 215, 0.5);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 43px;
  box-sizing: border-box;

  .recentTitle {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .leftLine {
      width: 7px;
      height: 24px;
      background: #D10000;
      margin-right: 8px;
    }

    .titleDes {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 26px;
      color: #353535;
      line-height: 38px;
      text-align: left;
      font-style: normal;
    }

  }

  .recentStudyList {
    width: 100%;
    background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/course/zuijinxuexibg.png') no-repeat;
    background-size: cover;

    .recentStudyListMore {
      display: flex;
      align-items: center;
      height: 25px;
      justify-content: flex-end;
      margin-top: 13px;
      padding-right: 48px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #343434;
      line-height: 25px;
      text-align: left;
      font-style: normal;
      margin-bottom: 5px;
      cursor: pointer;

    }
  }

  .waitStudy {
    .courseList {
      .courseItem {
        width: 330px;
        margin-right: 26px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .topImage {
          img {
            height: 186px;
          }
        }
      }
    }
  }

  .courseList {
    display: flex;
    padding: 0 36px;
    box-sizing: border-box;
    flex-wrap: wrap;

    .courseItem {
      width: 196px;
      height: 100%;
      margin-right: 15px;
      padding-bottom: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:nth-last-child(1) {
        margin-right: 0;
      }

      .topImage {
        width: 100%;

        img {
          width: 100%;
          height: 111px;
          object-fit: cover;
        }
      }

      .courseName {
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        color: #343434;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        box-sizing: border-box;
        margin-top: 10px;
      }

      .learnProgress {
        margin-top: 12px;
        width: 100%;
        height: 16px;
        box-sizing: border-box;
      }
    }
  }
}

.goodsList {
  width: 1200px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(239, 215, 215, 0.5);
  border-radius: 15px;
  margin-top: 47px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 42px;
  box-sizing: border-box;
  padding-bottom: 10px;

  .goodsTitle {
    width: 100%;
    margin-top: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 28px;
      color: #CC0D0A;
      line-height: 40px;
      text-align: center;
      font-style: normal;
    }

    dd {
      color: #343434;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
      text-align: center;
      font-style: normal;
    }

  }

  .lineView {
    width: 907px;
    height: 5px;
    background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/course/lineview.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .moreBtn {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 48px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #343434;
    line-height: 25px;
    text-align: left;
    font-style: normal;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .goodsListLine {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .goodsListLineItem {
      width: 265px;
      margin-right: 18px;
      margin-bottom: 24px;
      cursor: pointer;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .topImage {
        width: 100%;
        height: 150px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }


      .goodsName {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #343434;
        line-height: 25px;
        font-style: normal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        margin-top: 10px;
      }

      .priceV {
        height: 25px;
        display: flex;
        align-items: center;
        margin-top: 5px;

        span {
          height: 25px;
          font-weight: 500;
          font-size: 18px;
          color: #D10000;
          line-height: 25px;
          text-align: left;
          font-style: normal;
          margin-right: 28px;
        }

        dd {
          height: 22px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #979797;
          line-height: 22px;
          text-align: left;
          font-style: normal;
          text-decoration: line-through;


        }
      }
    }



  }

}

.emerCase {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  padding: 45px;
  box-sizing: border-box;
  border-radius: 24px;
  margin-top: 48px;
  position: relative;

  .moreBtn {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }
}

.classTopTitle {
  width: 100%;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  margin-bottom: 12px;
}

.lineView {
  width: 907px;
  height: 5px;
  background: url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/course/lineview.png') no-repeat;
  background-size: 100% 100%;
  margin: 20px auto 12px;
}

.emerCase-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 48px;
}

.emerCase-item {
  width: calc(25% - 14px);
  margin-right: 18px;
  margin-bottom: 18px;

  &:nth-child(4n) {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 198px;
    object-fit: cover;
    vertical-align: middle;
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: box;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 22px;
    height: 44px;
    margin-top: 10px;
    text-shadow: 0px 4px 16px rgba(34, 98, 188, 0.1);
  }

  .num {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-top: 10px;
    color: #353535;
  }
}
</style>